var render = function render(){var _vm=this,_c=_vm._self._c;return _c('common-layout',[_c('div',{staticClass:"top"},[_c('div',{staticClass:"header"},[_c('img',{staticClass:"logo",attrs:{"alt":"logo","src":require("@/assets/img/logo5.png")}})]),_c('div',{staticClass:"desc"})]),(_vm.loginType)?_c('div',{staticClass:"login"},[_c('div',{staticClass:"login-title"},[_c('div',{staticClass:"left-bac"}),_c('span',[_vm._v(_vm._s(_vm.judgeTicket ? "绑 定" : "登 录"))]),_c('div',{staticClass:"right-bac"})]),(_vm.judgeTicket)?_c('div',{staticClass:"wx-info"},[_c('img',{attrs:{"src":_vm.headimgurl,"alt":""}}),_c('span',[_vm._v(_vm._s(_vm.wx_nickname || "--"))])]):_vm._e(),_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.onSubmit}},[_c('a-alert',{directives:[{name:"show",rawName:"v-show",value:(_vm.error),expression:"error"}],staticStyle:{"margin-bottom":"24px"},attrs:{"type":"error","closable":true,"message":_vm.error,"showIcon":""}}),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'username',
            {
              rules: [
                { required: true, message: '请输入账号', whitespace: true },
              ],
            },
          ]),expression:"[\n            'username',\n            {\n              rules: [\n                { required: true, message: '请输入账号', whitespace: true },\n              ],\n            },\n          ]"}],attrs:{"autocomplete":"autocomplete","placeholder":"","size":"large"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'password',
            {
              rules: [
                { required: true, message: '请输入密码', whitespace: true },
              ],
            },
          ]),expression:"[\n            'password',\n            {\n              rules: [\n                { required: true, message: '请输入密码', whitespace: true },\n              ],\n            },\n          ]"}],attrs:{"size":"large","placeholder":"","autocomplete":"autocomplete"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-button',{staticStyle:{"width":"204px","margin":"30px 63px 0","background":"#FFCA5C","border":"none","border-radius":"8px"},attrs:{"loading":_vm.logging,"size":"large","htmlType":"submit","type":"primary"}},[_vm._v(_vm._s(_vm.judgeTicket ? "登 录" : "登 录"))])],1),(!_vm.judgeTicket)?_c('div',{staticClass:"login_code"},[_vm._v(" 其他登录方式: "),_c('a-icon',{staticClass:"icon",attrs:{"type":"wechat"},on:{"click":_vm.sweepCode}})],1):_vm._e()],1)],1):_c('div',{staticClass:"login_other"},[_c('div',{staticClass:"live_tools_header_title_components"},[_c('div',{staticClass:"login-title"},[_c('div',{staticClass:"left-bac"}),_c('span',[_vm._v("扫 码")]),_c('div',{staticClass:"right-bac"})])]),_c('div',{staticClass:"main"},[_c('div',{staticClass:"code_box"},[_c('a-spin',{attrs:{"spinning":_vm.sweepLoading}},[_c('img',{attrs:{"src":_vm.ticketData.ticket}})])],1),_c('p',{staticClass:"code_desc"},[_c('a-icon',{staticClass:"icon",staticStyle:{"font-size":"18px","margin-right":"10px"},attrs:{"type":"wechat"}}),_vm._v(" 请使用微信扫码登录 ")],1)]),(_vm.isShowinput)?_c('a',{staticClass:"footer",on:{"click":_vm.passwordLogin}},[_vm._v("账号密码登录")]):_vm._e(),_c('div',{staticClass:"box"})])])
}
var staticRenderFns = []

export { render, staticRenderFns }