<template>
  <common-layout>
    <div class="top">
      <div class="header">
        <img alt="logo" class="logo" src="@/assets/img/logo5.png" />
      </div>
      <div class="desc"></div>
    </div>
    <div class="login" v-if="loginType">
      <div class="login-title">
        <div class="left-bac"></div>
        <span>{{ judgeTicket ? "绑 定" : "登 录" }}</span>
        <div class="right-bac"></div>
      </div>
      <div v-if="judgeTicket" class="wx-info">
        <img :src="headimgurl" alt="" />
        <span>{{ wx_nickname || "--" }}</span>
      </div>
      <a-form @submit="onSubmit" :form="form">
        <a-alert
          type="error"
          :closable="true"
          v-show="error"
          :message="error"
          showIcon
          style="margin-bottom: 24px"
        />
        <a-form-item>
          <a-input
            autocomplete="autocomplete"
            placeholder=""
            size="large"
            v-decorator="[
              'username',
              {
                rules: [
                  { required: true, message: '请输入账号', whitespace: true },
                ],
              },
            ]"
          >
            <a-icon slot="prefix" type="user" />
          </a-input>
        </a-form-item>
        <a-form-item>
          <a-input-password
            size="large"
            placeholder=""
            autocomplete="autocomplete"
            v-decorator="[
              'password',
              {
                rules: [
                  { required: true, message: '请输入密码', whitespace: true },
                ],
              },
            ]"
          >
            <a-icon slot="prefix" type="lock" />
          </a-input-password>
        </a-form-item>
        <a-form-item>
          <a-button
            :loading="logging"
            style="
              width: 204px;
              margin: 30px 63px 0;
              background: #FFCA5C;
              border: none;
              border-radius: 8px;
            "
            size="large"
            htmlType="submit"
            type="primary"
            >{{ judgeTicket ? "登 录" : "登 录" }}</a-button
          >
        </a-form-item>
        <div class="login_code" v-if="!judgeTicket">
          其他登录方式:
          <a-icon type="wechat" class="icon" @click="sweepCode" />
        </div>
      </a-form>
    </div>
    <div class="login_other" v-else>
      <!-- <svg-icon
        icon-class="computer"
        class="icon-computer"
        @click="passwordLogin"
      /> -->
      <div class="live_tools_header_title_components">
        <div class="login-title">
          <div class="left-bac"></div>
          <span>扫 码</span>
          <div class="right-bac"></div>
        </div>
      </div>
      <div class="main">
        <div class="code_box">
          <a-spin :spinning="sweepLoading">
            <img :src="ticketData.ticket" />
          </a-spin>
        </div>

        <p class="code_desc">
          <a-icon
            type="wechat"
            class="icon"
            style="font-size: 18px; margin-right: 10px"
          />
          请使用微信扫码登录
        </p>
      </div>
      <a class="footer" @click="passwordLogin" v-if="isShowinput">账号密码登录</a>
      <div class="box"></div>
    </div>
  </common-layout>
</template>

<script>
import CommonLayout from "@/layouts/CommonLayout";
import { getAES } from "@/utils/aes";
import {
  setToken,
  setUserId,
  setPrincipalId,
  setPrincipalLogo,
  setPrincipalList,
  setPrincipalName,
  getToken,
  getUserId,
} from "@/utils/auth";
import { createNamespacedHelpers } from "vuex";
// import {setAuthorization} from '@/utils/request'
import { loadRoutes } from "@/utils/routerUtil";
// import {mapMutations} from 'vuex'
const { mapState, mapMutations, mapActions } = createNamespacedHelpers("auth");

export default {
  name: "Login",
  components: { CommonLayout },
  data() {
    return {
      logging: false,
      error: "",
      form: this.$form.createForm(this),
      loginType: true,
      timer: null,
      judgeTicket: false,
      headimgurl: "",
      wx_nickname: "",
      isShowinput: false
    };
  },
  computed: {
    ...mapState({
      principalsList: (state) => state.principalsList,
      list: (state) => state.list,
      ticketData: (state) => state.ticketData,
      sweepLoading: (state) => state.sweepLoading,
    }),
  },
  created() {
    this.sweepCode()
    // 内部使用账号密码登录方式
    this.isShowinput = this.$route.query.debug == 1 ? true : false
  },
  methods: {
    ...mapActions(["login", "getAuthority", "getPrincipals", "getSweepCode"]),
    // ...mapMutations('account', ['setUser', 'setPermissions', 'setRoles']),
    onSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err) => {
        if (!err) {
          this.logging = true;
          const username = this.form.getFieldValue("username");
          const password = this.form.getFieldValue("password");
          const grant_type = "password"; // password 与 ticket 模式
          let data = {
            username,
            password: getAES(password),
            grant_type,
            ticket: this.judgeTicket ? this.ticketData.key : "",
            client_module_id: 15
          };
          this.login(data)
            .then((res) => {
              console.log(res);
              this.afterLogin(res);
            })
            .catch((err) => {
              this.logging = false;
              this.$message.error(err.data.msg);
              // if (err.data.code == 50101002) {
              //   this.$message.error("该微信已绑定其他账号！");
              // }
              console.log(err);
            });
        }
      });
    },
    async afterLogin(res) {
      this.logging = false;
      const loginRes = res.data;
      if (loginRes.code == 200) {
        const { token, id, token_head } = loginRes.data;
        setToken(token_head + token);
        setUserId(id);
        await this.getAuthority();
        await this.getPrincipals();
        console.log(this.principalsList);
        console.log(11, this.list);
        if (this.principalsList.length > 0 && this.list.length > 0) {
          // 存入主体id
          setPrincipalId(this.principalsList[0].principal_id);
          setPrincipalLogo(this.principalsList[0].logo);
          setPrincipalName(this.principalsList[0].principal_name);
          setPrincipalList(this.principalsList);
          this.$store.commit('auth/SET_PRINCIPAL_LOGO', this.principalsList[0].logo)
          // 加载路由
          let config = [
            {
              name: "首页",
              path: "/",
              redirect: "/login",
              router: "root",
              children: this.list,
            },
          ];
          loadRoutes(config);
          // 默认跳转第一个路由
          this.$router.push(this.list[0].children[0].path);
        } else {
          console.log("没有菜单权限或没有配置主体，请联系管理员");
        }
      } else {
        this.error = loginRes.message;
      }
    },
    // 微信二维码获取
    sweepCode() {
      this.loginType = false;
      this.getSweepCode();
      this.circulationLogin();
    },
    // 轮循登录接口
    circulationLogin() {
      this.timer = setInterval(() => {
        this.loginTick();
      }, 2000);
    },
    // 带ticket登录
    loginTick(type) {
      const username = this.form.getFieldValue("username");
      const password = this.form.getFieldValue("password");
      let data = {
        username: username ? username : "",
        password: password ? password : "",
        grant_type: type ? "password" : "ticket",
        ticket: this.ticketData.key,
        client_module_id: 15
      };
      this.login(data)
        .then((res) => {
          console.log("轮循登录返回", res);
          clearInterval(this.timer);
          this.afterLogin(res);
        })
        .catch((res) => {
          console.log("出错处理", res);
          switch (res.data.code) {
            case 50101001:
              console.log("未扫码");
              break;
            case 50101004:
              this.$message.warning(res.data.msg);
              this.passwordLogin();
              this.headimgurl = res.data.data ? res.data.data.headimgurl : '';
              this.wx_nickname = res.data.data ? res.data.data.wx_nickname : '';
              this.judgeTicket = true;
              break;
            case 427:
              this.$message.error(res.data.msg);
              clearInterval(this.timer);
              this.sweepCode();
              break;
            default:
              break;
          }
        });
    },
    passwordLogin() {
      this.loginType = true;
      clearInterval(this.timer);
    },
    beforeDestroy() {
      clearInterval(this.timer);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";

.common-layout /deep/ {
  background-color: #000;

  .ant-input-affix-wrapper .ant-input {
    background: #224564;
    border: none;
  }
  .ant-input-affix-wrapper .ant-input-prefix,
  .ant-input-affix-wrapper .ant-input-suffix {
    color: rgba(255, 255, 255, 0.2);
  }
  .ant-input-password-icon {
    color: rgba(255, 255, 255, 0.6);
  }
}
</style>

<style lang="less" scoped>
.common-layout {
  .top {
    text-align: center;
    .header {
      height: 111px;
      line-height: 111px;
      a {
        text-decoration: none;
      }
      .logo {
        height: 111px;
        vertical-align: top;
        margin-right: 16px;
      }
      .title {
        font-size: 33px;
        color: @title-color;
        font-family: "Myriad Pro", "Helvetica Neue", Arial, Helvetica,
          sans-serif;
        font-weight: 600;
        position: relative;
        top: 2px;
      }
    }
    .desc {
      font-size: 14px;
      color: @text-color-second;
      margin-top: 12px;
      margin-bottom: 40px;
    }
  }
  .login {
    width: 430px;
    height: 449px;
    padding: 0 50px;
    background: #1C1E27;
    border-radius: 26px;
    margin: 0 auto;
    @media screen and (max-width: 576px) {
      width: 95%;
    }
    @media screen and (max-width: 320px) {
      .captcha-button {
        font-size: 14px;
      }
    }
    .icon {
      font-size: 24px;
      color: rgba(255, 255, 255, 0.65);
      margin-left: 16px;
      vertical-align: middle;
      cursor: pointer;
      transition: color 0.3s;

      &:hover {
        color: @primary-color;
      }
    }
    .login_code {
      color: rgba(255, 255, 255, 0.65);
    }
  }
  .wx-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 36px;
    > img {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      margin-right: 8px;
    }
    > span {
      font-size: 18px;
      color: rgba(255, 255, 255, 0.7);
    }
  }

  .login-title {
    width: 100%;
    height: 125px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    > span {
      font-size: 18px;
      color: #fff;
      margin: 0 13px;
    }
    .left-bac {
      width: 60px;
      height: 4px;
      background: linear-gradient(
        270deg,
        #ffffff 0%,
        rgba(255, 255, 255, 0) 100%,
        rgba(255, 255, 255, 0) 100%
      );
      border-radius: 2px;
      opacity: 0.3;
    }
    .right-bac {
      width: 60px;
      height: 4px;
      background: linear-gradient(
        270deg,
        rgba(255, 255, 255, 0) 0%,
        #ffffff 100%
      );
      border-radius: 2px;
      opacity: 0.3;
    }
  }
  .login_other {
    position: relative;
    width: 430px;
    height: 449px;
    padding: 0 50px;
    background: #1C1E27;
    border-radius: 26px;
    margin: 0 auto;
    @media screen and (max-width: 576px) {
      width: 95%;
    }
    @media screen and (max-width: 320px) {
      .captcha-button {
        font-size: 14px;
      }
    }
    .live_tools_header_title_components {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      .title {
        color: #fff;
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        &::before {
          width: 8px;
          height: 16px;
          border-radius: 8px;
          content: "";
          display: inline-block;
          width: 6px;
          height: 14px;
          background-image: linear-gradient(0deg, #4f66ff, #7789ff);
          margin-right: 10px;
          margin-top: 1px;
        }
      }
    }
    .main {
      .code_box {
        width: 200px;
        height: 200px;
        background: #fff;
        margin: 0 auto;
        border-radius: 4px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .code_desc {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 24px;
        font-family: PingFangSC-Regular;
        font-size: 12px;
        line-height: 12px;
        color: #0ad068;
      }
      .icons_sweep {
        margin-right: 4px;
        //  color: #0ad068;
      }
    }
    .footer {
      display: block;
      color: #bcc2d9;
      font-size: 12px;
      text-align: right;
      margin-right: 24px;
      &:hover {
        color: #4f66ff;
      }
    }

    .icon-computer {
      position: absolute;
      z-index: 9;
      top: 10px;
      right: 10px;
      color: #505773;
      font-size: 45px;
      line-height: 48px;
      cursor: pointer;
      transition: all 0.1s ease-in-out;
      &:hover {
        color: #646d8f;
      }
    }
  }
}
</style>
